// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Small Saucepan" location={location}>
    <SEO title="Contact Us" />
    <h1>Contact Us</h1>
    <p>
      To get in touch about anything Small Saucepan related, send an email to{" "}
      <a href="mailto:james@smallsaucepan.com">james@smallsaucepan.com</a>
    </p>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
